import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, Collapse, CardHeader } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
// Form Editor
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from "react-router-dom";
import Accordian from '../../components/Common/Accordian';
import { getItems, deleteItem, addItem } from '../../services/PageFaqServices';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { confirmAlert } from 'react-confirm-alert';
import { logoutAction } from '../../redux/actions/UserActions';

class HelpPageFAQ extends Component {
    state = {
        description: EditorState.createEmpty(),
        title: '',
        items: [],
        loading: false,
        type: this.props.type,
        id: null
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchItems();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    componentWillReceiveProps = async (nextProps) => {
        if (nextProps.type !== this.props.type) {
            await this.setState({
                type: parseInt(nextProps.type)
            })
            this.fetchItems();
        }
    }

    fetchItems = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getItems({
                token: this.props.sessionProps.account.token,
                type: this.state.type
            });
            await this.setState({
                items: response.data.data.items,
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onEditorStateChange = (description) => {
        this.setState({
            description: description
        });
    };

    _delete = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await deleteItem({
                token: this.props.sessionProps.account.token,
                id: id
            });
            toast(`Pregunta eliminada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
            this.fetchItems();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    delete = async (id) => {
        confirmAlert({
            title: 'Eliminar pregunta',
            message: `¿Esta seguro que desea eliminar la pregunta seleccionada?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._delete(id)
                    }
                }
            ]
        });
    }

    save = async () => {
        await this.setState({ loading: true });
        try {
            let response = await addItem({
                token: this.props.sessionProps.account.token,
                title: this.state.title,
                description: stateToHTML(this.state.description.getCurrentContent()),
                id: this.state.id,
                type: this.state.type
            });
            await this.setState({
                title: '',
                description: EditorState.createEmpty(),
                id: null,
                loading: false
            })
            toast(`Pregunta guardada con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchItems();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    edit = async (item) => {
        await this.setState({
            id: item.id,
            title: item.title,
            description: EditorState.createWithContent(stateFromHTML(item.description))
        });
    }

    render() {
        return (
            <div className="page-content" style={{ paddingTop: 0, paddingLeft: 0, paddingRight: 0 }}>
                {this.state.loading && <Loading />}
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <h5 style={{ marginBottom: '1rem' }}>Preguntas</h5>
                                    <FormGroup>
                                        <Label>Título</Label>
                                        <Input name="title" className="form-control" type="text" placeholder="Ingrese el título"
                                            value={this.state.title} onChange={this.handleChange} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>Descripción</Label>
                                        <Editor
                                            editorState={this.state.description}
                                            onEditorStateChange={this.onEditorStateChange}
                                            toolbarClassName="toolbarClassName"
                                            wrapperClassName="wrapperClassName"
                                            editorClassName="editorClassName"
                                            toolbar={{
                                                options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                inline: { inDropdown: true },
                                                link: { inDropdown: true },
                                            }}
                                        />
                                    </FormGroup>
                                    <FormGroup className="mb-0 text-right">
                                        <div>
                                            <Button onClick={this.save} type="submit" color="primary" className="ml-2">
                                                Guardar
                                            </Button>
                                        </div>
                                    </FormGroup>
                                    <div className="custom-accordion-arrow mt-5">
                                        {
                                            this.state.items.map(item =>
                                                <Accordian
                                                    title={item.title}
                                                    description={item.description}
                                                    delete={() => this.delete(item.id)}
                                                    edit={() => this.edit(item)}
                                                />
                                            )
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(HelpPageFAQ);