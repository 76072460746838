import React, { Component } from 'react';
import {
    Col, Row, Card, CardBody, Container, FormGroup
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import axios from 'axios';
import { connect } from 'react-redux';
import { Constants } from '../../Constants';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import moment from 'moment';

class Communications extends Component {
    state = {
        numberTest: '',
        message: '',
        showButton: true,
    }

    componentDidMount = async () => {
        toast.configure();
    }

    sendMessage = async () => {
        // guardo en local storage el ultimo envío para no dejar enviar por las proximas 12 horas
        if (!this.state.numberTest) {
            localStorage.setItem('message', moment().format('YYYY-MM-DD HH:mm:ss'));
            await this.setState({ loading: true, showButton: false });
        }
        else {
            await this.setState({ loading: true });
        }
        try {
            axios.post(`${Constants.BASE_URL}customers/sendCommunication`, { message: this.state.message, numberTest: this.state.numberTest }, {
                headers: {
                    'Authorization': this.props.sessionProps.account.token
                }
            });
            if (!this.state.numberTest) {
                await this.setState({ loading: false, message: '' });
            }
            else {
                await this.setState({ loading: false });

            }
            toast('Comunicación enviada correctamente', {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Comunicaciones" breadcrumbItems={[]} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <FormGroup row>
                                            <label>Mensaje (tiempo estimado 3 horas cada 1000 usuarios)</label>
                                            <textarea className="form-control" rows="10"
                                                value={this.state.message}
                                                onChange={(e) => this.setState({ message: e.target.value })}
                                                placeholder="Escribe el mensaje que deseas enviar"
                                            ></textarea>
                                            <input type="text" className="form-control mt-2"
                                                value={this.state.numberTest}
                                                onChange={(e) => this.setState({ numberTest: e.target.value })}
                                                placeholder="Puedes ingresar un número de prueba para enviar primero aquí y ver como llega" />
                                            {
                                                this.state.showButton && (!localStorage.getItem('message') || moment().diff(localStorage.getItem('message'), 'hours') > 12) ?
                                                    <button className="btn btn-primary mt-2" onClick={() => this.sendMessage()}>Enviar</button>
                                                    : <div className="text-danger">Ya se envió una comunicación en las últimas 12 horas</div>
                                            }
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container >
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Communications);
