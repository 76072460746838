import React, { Component } from 'react';
import Loading from '../../components/Loading';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input, Button, Table } from 'reactstrap';
import './AuctionConfig.scss';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

// Form Editor
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { getConfigs, getOffersConfig, putConfigs } from '../../services/AuctionServices';
import { Link } from "react-router-dom";
import Select from 'react-select';

class AuctionConfig extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remates", link: "#" },
            { title: "Configuración", link: "#" }
        ],
        description: EditorState.createEmpty(),
        info_aditional: EditorState.createEmpty(),
        loading: false,
        offersConfig: [],
        isModified: false,
        amount: '',
        value: '',
        currency: { value: 1, label: '$' }
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchConfigs();
        this.fetchOfferConfigs();
    }

    fetchConfigs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getConfigs({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                loading: false,
                description: EditorState.createWithContent(stateFromHTML(response.data.data.config.default_description)),
                info_aditional: EditorState.createWithContent(stateFromHTML(response.data.data.config.info_aditional))
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    fetchOfferConfigs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await getOffersConfig({
                token: this.props.sessionProps.account.token,
            });
            await this.setState({
                loading: false,
                offersConfig: response.data.configs
            })
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    onEditorStateChange = (description) => {
        this.setState({
            description: description
        });
    };

    onEditorStateChangeInfoAdicional = (info_aditional) => {
        this.setState({
            info_aditional: info_aditional
        });
    };

    saveConfigs = async () => {
        await this.setState({ loading: true });
        try {
            let response = await putConfigs({
                token: this.props.sessionProps.account.token,
                description: stateToHTML(this.state.description.getCurrentContent()),
                info_aditional: stateToHTML(this.state.info_aditional.getCurrentContent()),
                offersConfig: this.state.offersConfig
            });
            await this.setState({
                loading: false
            })
            toast(`Configuraciónes guardadas con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    saveOfferConfig = async () => {
        if (this.state.amount === '' || this.state.value === '') {
            toast.error(`Valor hasta y valor que aumenta son obligatorios`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        let offersConfig = this.state.offersConfig;
        if (this.state.isModified) {
            for (let offerConfig of offersConfig) {
                if (offerConfig.id === this.state.isModified) {
                    offerConfig.amount = this.state.amount;
                    offerConfig.value = this.state.value;
                    offerConfig.currency = this.state.currency.value;
                    break;
                }
            }
        } else {
            offersConfig.push({
                amount: this.state.amount,
                value: this.state.value,
                currency: this.state.currency.value
            });
        }
        offersConfig.sort(function (a, b) {
            // Comparar primero por currency
            if (a.currency < b.currency) {
                return -1;
            }
            if (a.currency > b.currency) {
                return 1;
            }
            // Si la currency es la misma, entonces comparamos por amount
            if (a.amount < b.amount) {
                return -1;
            }
            if (a.amount > b.amount) {
                return 1;
            }
            // Si tanto la currency como el amount son iguales, retornamos 0
            return 0;
        });
        await this.setState({
            offersConfig: offersConfig,
            amount: '',
            value: '',
            currency: { value: 1, label: '$' },
            isModified: false
        })
    }

    edit = async (item) => {
        await this.setState({
            amount: item.amount,
            value: item.value,
            currency: { value: item.currency, label: item.currency === 1 ? '$' : 'USD' },
            isModified: item.id
        });
    }

    delete = async (id) => {
        let offersConfig = this.state.offersConfig;
        let i = 0;
        for (let offerConfig of offersConfig) {
            if (offerConfig.id === id) {
                offersConfig.splice(i, 1);
                break;
            }
            i += 1;
        }
        await this.setState({
            offersConfig: offersConfig
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Configuración remates" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <FormGroup>
                                            <Label htmlFor="productdesc">Descripción predeterminada</Label>
                                            <Editor
                                                editorState={this.state.description}
                                                onEditorStateChange={this.onEditorStateChange}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                    inline: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup style={{ marginTop: '2rem' }}>
                                            <Label>Información adicional</Label>
                                            <Editor
                                                editorState={this.state.info_aditional}
                                                onEditorStateChange={this.onEditorStateChangeInfoAdicional}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                toolbar={{
                                                    options: ['inline', 'blockType', 'link', 'emoji', 'image'],
                                                    inline: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup style={{ marginTop: '2rem' }}>
                                            <Label>Rangos de oferta</Label>
                                            <div className="row">
                                                <div class="col-12 col-md-8 order-2">
                                                    <div className="table-responsive">
                                                        <Table className="mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Moneda</th>
                                                                    <th>Hasta</th>
                                                                    <th>Aumenta</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    this.state.offersConfig.map(item =>
                                                                        <tr>
                                                                            <td>{item.currency === 1 ? '$ ' : 'USD '}</td>
                                                                            <td>{item.currency === 1 ? '$ ' : 'USD '}{item.amount}</td>
                                                                            <td>{item.currency === 1 ? '$ ' : 'USD '}{item.value}</td>
                                                                            <td>
                                                                                <React.Fragment>
                                                                                    <Link onClick={() => this.edit(item)} className="text-primary"><i className="mdi mdi-pencil font-size-18"></i></Link>
                                                                                    <Link onClick={() => this.delete(item.id)} className="text-danger"><i className="mdi mdi-trash-can font-size-18"></i></Link>
                                                                                </React.Fragment>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4 order-1">
                                                    <FormGroup>
                                                        <Label>Valor hasta</Label>
                                                        <Input name="amount" className="form-control" type="text" placeholder="Hasta este valor"
                                                            value={this.state.amount} onChange={this.handleChange} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Valor que aumenta</Label>
                                                        <Input name="value" className="form-control" type="text" placeholder="Aumentará este"
                                                            value={this.state.value} onChange={this.handleChange} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label>Moneda</Label>
                                                        <Select
                                                            name="currency"
                                                            options={[{ value: 1, label: '$' }, { value: 2, label: 'USD' }]}
                                                            className="select2 select2-multiple"
                                                            placeholder="Seleccionar moneda"
                                                            value={this.state.currency}
                                                            onChange={(value) => this.setState({
                                                                currency: value
                                                            })}
                                                        />
                                                    </FormGroup>

                                                    <Button onClick={this.saveOfferConfig} type="submit" color="info" className="w-100">
                                                        {this.state.isModified ? 'Modificar' : 'Agregar'}
                                                    </Button>
                                                </div>
                                            </div>
                                        </FormGroup>
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '2rem' }}>
                                            <div>
                                                <Button onClick={this.saveConfigs} type="submit" color="primary" className="ml-2">
                                                    Guardar
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

export default connect(mapStateToProps, null)(AuctionConfig);