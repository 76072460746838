import React, { useEffect, useState } from 'react';
import './styles.scss';
import Loading from '../Loading';
import { Card, CardBody, Col, Container, FormGroup, Label, Row, Table } from 'reactstrap';
import Select from 'react-select';
import moment from 'moment';
import { customersListAutocomplete } from '../../services/UserServices';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom';
import { connect } from 'react-redux';
import { logoutAction } from '../../redux/actions/UserActions';

const SelectUsersForAuction = ({ onClose, onSave, selectedUsers, sessionProps, logout }) => {
    const [customers, setCustomers] = useState([])
    const [loading, setLoading] = useState(false)
    const [selectedUsersTmp, setSelectedUsersTmp] = useState(selectedUsers)
    const history = useHistory()

    useEffect(() => {
        fetchCustomers()
    }, [])

    const fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: sessionProps.account.token,
                addExtraData: 1
            });
            setCustomers(response.data.data.customers);
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                logout();
                useHistory.push('/');
            }
            else {
                console.log(error)
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    const addUser = (e) => {
        const user = e.value
        setSelectedUsersTmp([
            ...selectedUsersTmp,
            {
                email: user?.email || '',
                name: user?.name || '',
                phone: user?.phone || '',
                id: user?.id_user || null
            }
        ])
    }

    return (
        <div className="container-modal">
            {loading && <Loading />}
            <div className="content-modal">
                <Container>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-10">
                                            <h5 style={{ marginBottom: 0 }}>Usuarios habilitados</h5>
                                        </div>
                                        <div className="col-2" onClick={() => onClose()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            <h5 style={{ marginBottom: 0 }}>X</h5>
                                        </div>
                                    </div>
                                    <hr style={{ marginTop: '0.5rem' }} />
                                    <FormGroup row>
                                        <Label className="col-md-2 col-form-label">Usuario</Label>
                                        <Col md={10} style={{ zIndex: 9, display: 'flex' }}>
                                            <Select
                                                name="features"
                                                options={customers
                                                    // filtro que no este ya seleccionado
                                                    .filter(customer => !selectedUsersTmp.find(selected => selected.id === customer.id_user))
                                                    .map(item => ({ value: item, label: item.name }))}
                                                className="select2 select2-multiple w-100"
                                                placeholder="Seleccionar comprador a buscar"
                                                onChange={(e) => addUser(e)}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <div style={{ marginTop: '1rem' }}>
                                        <h5>Usuarios habilitados</h5>
                                    </div>
                                    <div className="table-responsive" style={{ marginTop: '1rem' }}>
                                        <Table className="mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Email</th>
                                                    <th>Nombre</th>
                                                    <th>Celular</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    selectedUsersTmp.map(item =>
                                                        <tr>
                                                            <td>{item.email}</td>
                                                            <td>{item.name}</td>
                                                            <td>{item.phone}</td>
                                                            <td>
                                                                <button onClick={() => setSelectedUsersTmp(selectedUsersTmp.filter(selected => selected.id !== item.id))} className="btn btn-danger">
                                                                    Eliminar
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </div>

                                    <div>
                                        <button onClick={() => onClose()} className="btn btn-secondary" style={{ marginRight: '1rem' }}>Cancelar</button>
                                        <button onClick={() => onSave(selectedUsersTmp)} className="btn btn-primary">Guardar</button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});


const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectUsersForAuction);