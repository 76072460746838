import axios from 'axios';
import { Constants } from '../Constants';

export const getAccountConfigs = async () => {
    try {
        return await axios.get(`${Constants.BASE_URL}account`);
    } catch (error) {
        throw error;
    }
}

export const putAccountConfigs = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}account`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const putAccountConfigsColors = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}account/colors`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}
