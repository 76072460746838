import axios from 'axios';
import { Constants } from '../Constants';

export const getAlertsByClient = async (client_id) => {
    try {
        return await axios.get(`${Constants.BASE_PAYMENTS_URL}alerts/${client_id}`);
    } catch (error) {
        throw error;
    }
}

export const setAlertSeen = async (alert_id) => {
    try {
        return await axios.patch(`${Constants.BASE_PAYMENTS_URL}alerts/${alert_id}`);
    } catch (error) {
        throw error;
    }
}