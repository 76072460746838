import React, { Component } from 'react';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import {
    Col, Row, Card, CardBody, Container, Table,
    Nav, NavItem, NavLink, Input
} from 'reactstrap';
import PaginationCustom from '../../components/pagination/Pagination';
import { Link } from "react-router-dom";
import { getCustomers, blockCustomer, unblockCustomer } from '../../services/UserServices';
import { connect } from 'react-redux';
import './CustomersList.scss';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import ModalCustomer from '../../components/ModalCustomer/ModalCustomer';
import Select from 'react-select';
import ModalLotsOfCustomer from '../../components/customers/ModalLotsOfCustomer/ModalLotsOfCustomer';

class CustomersList extends Component {
    state = {
        breadcrumbItems: [
            { title: "Clientes", link: "#" },
            { title: 'Listado', link: "#" }
        ],
        activeTab: '0',
        page: 1,
        pages: 0,
        customers: [],
        loading: false,
        customerIdSelect: null,
        search: '',
        orderBy: { value: 'nameA', label: 'Orden alfabético ASC' },
        customerShowLots: null,
        customerShowLotsTitle: '',
    }

    componentDidMount = () => {
        this.fetchCustomers();
        toast.configure();
    }

    fetchCustomers = async (loading = true) => {
        loading && await this.setState({ loading: true });
        try {
            let response = await getCustomers({
                token: this.props.sessionProps.account.token,
                page: this.state.page,
                filter: this.state.activeTab,
                search: encodeURIComponent(this.state.search),
                orderBy: this.state.orderBy.value
            });
            await this.setState({
                pages: response.data.data.pages,
                customers: response.data.data.customers,
                loading: false
            })
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    onPrev = async () => {
        await this.setState({ page: this.state.page - 1 });
        this.fetchCustomers();
    }

    onNext = async () => {
        await this.setState({ page: this.state.page + 1 });
        this.fetchCustomers();
    }

    onPage = async (page) => {
        await this.setState({ page: page });
        this.fetchCustomers();
    }

    toggleTab = async (tab) => {
        if (this.state.activeTab !== tab) {
            await this.setState({
                activeTab: tab,
                page: 1
            });
            this.fetchCustomers();
        }
    }

    block = (id, title) => {
        // al confirmar poder escribir la razon del bloqueo
        let reasonBlock = '';
        confirmAlert({
            title: 'Bloquear cliente',
            message: `¿Esta seguro que desea bloquear a ${title}?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._block(id, reasonBlock);
                    }
                }
            ],
            childrenElement: () =>
                <textarea className="form-control" placeholder="Razón del bloqueo" rows={10}
                    defaultValue={reasonBlock} onChange={(e) => { reasonBlock = e.target.value }}
                />,
        });
    }

    _block = async (id, reasonBlock) => {
        await this.setState({ loading: true });
        try {
            let response = await blockCustomer({
                token: this.props.sessionProps.account.token,
                id: id,
                reasonBlock
            });
            await this.setState({ loading: false });
            toast("Cliente bloqueado con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchCustomers(false);
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
            console.log(error)
        }
    }

    unBlock = async (id, title) => {
        confirmAlert({
            title: 'Desbloquear cliente',
            message: `¿Esta seguro que desea desbloquear a ${title}?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._unBlock(id);
                    }
                }
            ]
        });
    }

    _unBlock = async (id) => {
        await this.setState({ loading: true });
        try {
            let response = await unblockCustomer({
                token: this.props.sessionProps.account.token,
                id: id
            });
            await this.setState({ loading: false });
            toast("Cliente desbloqueado con éxito", {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            this.fetchCustomers(false);
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    closeModal = async () => {
        await this.setState({
            customerIdSelect: null
        });
        this.fetchCustomers(true);
    }

    handleChangeSearch = async (event) => {
        const { name, value } = event.target;
        await this.setState({
            [name]: value,
            page: 1
        });
        this.fetchCustomers(false);
    }

    changeOrderBy = async (e) => {
        await this.setState({ orderBy: e })
        this.fetchCustomers();
    }

    render() {
        return (
            <div>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Listado de clientes" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody className="without-padding">
                                        <Nav tabs className="nav-tabs-custom mb-4">
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('0'); }} className={`${this.state.activeTab === '0' && 'active'} font-weight-bold p-3`}>Todos</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('1'); }} className={`${this.state.activeTab === '1' && 'active'} font-weight-bold p-3`}>Activos</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('2'); }} className={`${this.state.activeTab === '2' && 'active'} p-3 font-weight-bold`}>Bloqueados</NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink onClick={() => { this.toggleTab('3'); }} className={`${this.state.activeTab === '3' && 'active'} p-3 font-weight-bold`}>Deudores</NavLink>
                                            </NavItem>
                                        </Nav>
                                        <div class="row">
                                            <div className="col-3">
                                                <div className="form-inline mb-2">
                                                    <div className="search-box">
                                                        <div className="position-relative">
                                                            <Input type="text" name="search" className="form-control rounded" placeholder="Buscar # o nombre"
                                                                value={this.state.search} onChange={this.handleChangeSearch} />
                                                            <i className="mdi mdi-magnify search-icon"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <PaginationCustom page={this.state.page} pages={this.state.pages}
                                                    onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                            </div>
                                            <div className="col-5 text-right div-btn-add">
                                                <Link onClick={() => this.setState({ customerIdSelect: 0 })} className="btn btn-success btn-add w-xs" style={{ marginRight: '1rem' }}>
                                                    <i className="mdi mdi-plus"></i> Agregar
                                                </Link>
                                                <Select
                                                    name="orderBy"
                                                    className="select2 select2-multiple w-50"
                                                    options={[
                                                        { value: 'nameA', label: 'Orden alfabético ASC' },
                                                        { value: 'nameD', label: 'Orden alfabético DESC' },
                                                        { value: 'idA', label: 'Orden por código ASC' },
                                                        { value: 'idD', label: 'Orden por código DESC' }
                                                    ]}
                                                    value={this.state.orderBy}
                                                    onChange={(e) => this.changeOrderBy(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Nombre</th>
                                                        <th>Email</th>
                                                        <th>Teléfono</th>
                                                        <th>Dirección</th>
                                                        {
                                                            window.api_name === 'rm' ? (
                                                                <>
                                                                    <th>Validación código</th>
                                                                    <th>Validación estado</th>
                                                                </>
                                                            ) : null
                                                        }
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.customers.map(item =>
                                                            <tr>
                                                                <th scope="row">{item.id_user}</th>
                                                                <td>{item.name}
                                                                    {
                                                                        item.fb &&
                                                                        <i className="mdi mdi-facebook" style={{ color: 'blue' }}></i>
                                                                    }
                                                                </td>
                                                                <td>{item.email}</td>
                                                                <td>{item.phone} - {item.telephone}</td>
                                                                <td>{item.address}{item.address ? ', ' : ''}
                                                                    {item.city}{item.city ? ', ' : ''}
                                                                    {
                                                                        this.props.configAccount.config?.show_department ?
                                                                            `${item.departament ? item.departament.name : ''}`
                                                                            : null
                                                                    }
                                                                </td>
                                                                {
                                                                    window.api_name === 'rm' ? (
                                                                        <>
                                                                            <td>{item.validation_code || '-'}</td>
                                                                            <td>{item.validation_code && item.validation_status ?
                                                                                'Validado' : item.validation_code ? 'Sin validar' : '-'}
                                                                            </td>
                                                                        </>
                                                                    ) : null
                                                                }
                                                                <td>
                                                                    {
                                                                        item.active ?
                                                                            <React.Fragment>
                                                                                <Link onClick={() => this.block(item.id, item.name)} className="text-danger" id={"bloquear"}><i className="mdi mdi-cancel font-size-18"></i></Link>
                                                                            </React.Fragment>
                                                                            :
                                                                            <React.Fragment>
                                                                                <Link onClick={() => this.unBlock(item.id, item.name)} className="text-primary" id={"desbloquear"}><i className="mdi mdi-check-circle-outline font-size-18"></i></Link>
                                                                            </React.Fragment>
                                                                    }
                                                                    <React.Fragment>
                                                                        <Link to="#" onClick={() => this.setState({ customerIdSelect: item.id })} className="text-success"><i className="mdi mdi-account-edit font-size-22" style={{ marginLeft: '5px' }}></i></Link>
                                                                    </React.Fragment>
                                                                    {
                                                                        this.state.activeTab === '3' &&
                                                                        (
                                                                            <Link onClick={() => this.setState({ customerShowLots: item.id_user, customerShowLotsTitle: item.name })} className="text-success">
                                                                                <i className="mdi mdi-format-list-numbered font-size-18"></i>
                                                                            </Link>
                                                                        )
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        <PaginationCustom page={this.state.page} pages={this.state.pages}
                                            onPrev={this.onPrev} onNext={this.onNext} onPage={this.onPage} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {
                    this.state.customerIdSelect !== null &&
                    <ModalCustomer customerId={this.state.customerIdSelect} {...this.props} close={() => this.closeModal()} />
                }
                {
                    this.state.customerShowLots !== null && (
                        <ModalLotsOfCustomer id={this.state.customerShowLots} close={() => this.setState({
                            customerShowLots: null
                        })} title={this.state.customerShowLotsTitle} />
                    )
                }
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomersList);