import axios from 'axios';
import { Constants } from '../Constants';

export const updateHead = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.put(`${Constants.BASE_URL}pagefaq/head`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const addItem = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}pagefaq/item`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getHead = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}pagefaq/head?type=${data.type}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getItems = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}pagefaq/items?type=${data.type}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteItem = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}pagefaq/item/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}

