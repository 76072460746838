import React, { Component } from 'react';
import { Collapse, Card, CardBody, CardHeader, Button } from "reactstrap";
import { Link } from "react-router-dom";

class Accordian extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
        this.openClose = this.openClose.bind(this);
    }

    openClose() {
        this.setState({ open: !this.state.open });
    }

    delete = (e) => {
        e.stopPropagation();
        this.props.delete();
    }

    edit = (e) => {
        e.stopPropagation();
        this.props.edit();
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <Link to="#" className="text-dark" onClick={this.openClose} style={{ cursor: "pointer" }}>
                        <CardHeader id="gen-question-headingOne">
                            <h5 className="font-size-14 m-0">
                                <i className={this.state.open ? "mdi mdi-chevron-up accor-arrow-icon" : "mdi mdi-chevron-right accor-arrow-icon"}></i>
                                {this.props.title}
                            </h5>
                            <Link className="text-info" style={{ position: 'absolute', right: '3rem', top: 0, bottom: 0, fontSize: '26px' }}
                                onClick={this.edit}>
                                <i className="mdi mdi-pencil font-size-18"></i>
                            </Link>
                            <Link className="text-danger" style={{ position: 'absolute', right: '1rem', top: 0, bottom: 0, fontSize: '26px' }}
                                onClick={this.delete}>
                                <i className="mdi mdi-trash-can font-size-18"></i>
                            </Link>
                        </CardHeader>
                    </Link>

                    <Collapse isOpen={this.state.open}>
                        <CardBody>
                            <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
                        </CardBody>
                    </Collapse>
                </Card>
            </React.Fragment>
        );
    }
}

export default Accordian;