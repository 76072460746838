import axios from 'axios';
import { Constants } from '../Constants';

export const addBanner = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.post(`${Constants.BASE_URL}banners`, data, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const getBanners = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.get(`${Constants.BASE_URL}banners`, httpOptions);
    } catch (error) {
        throw error;
    }
}

export const deleteBanner = async (data) => {
    try {
        const httpOptions = {
            headers: {
                'Authorization': data.token
            }
        };
        return await axios.delete(`${Constants.BASE_URL}banners/${data.id}`, httpOptions);
    } catch (error) {
        throw error;
    }
}