import React, { Component } from 'react';

class Loading extends Component {
    render() {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <i className="ri-loader-line spin-icon"></i>
                    </div>
                    {
                        this.props.processFiles ? (
                            <p>{this.props.processFiles}</p>
                        ) : null
                    }
                    {
                        this.props.uploadedFiles ? (
                            <p>{this.props.uploadedFiles}</p>
                        ) : null
                    }
                </div>
            </div>
        );
    }
}

export default Loading;