import React, { Component } from 'react';
import {
    Col, Row, Card, CardBody, Container, FormGroup, Label, CustomInput,
    Table,
} from 'reactstrap';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import axios from 'axios';
import { connect } from 'react-redux';
import { Constants } from '../../Constants';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import { Link } from "react-router-dom";

class Files extends Component {
    state = {
        files: []
    }

    componentDidMount() {
        this.fetchFiles();
    }

    fetchFiles = async () => {
        await this.setState({ loading: true });
        try {
            const result = await axios.get(`${Constants.BASE_URL}files`, {
                headers: {
                    'Authorization': this.props.sessionProps.account.token
                }
            });
            this.setState({
                files: result.data.data,
                loading: false,
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            console.log(error)

            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    handleFileChange = async (event) => {
        for (let file of event.target.files) {
            await this.setState({ loading: true });
            var form = new FormData();
            form.append('file', file);
            try {
                const result = await axios.post(`${Constants.BASE_URL}auctions/image_upload?type=files`, form, {
                    headers: {
                        'Authorization': this.props.sessionProps.account.token,
                        'content-type': 'multipart/form-data'
                    }
                });
                this.setState({
                    loading: false
                });
                this.fetchFiles()
            } catch (error) {
                await this.setState({ loading: false });
                if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                    this.props.logout();
                    this.props.history.push('/');
                }
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    deleteFile = async (url) => {
        await this.setState({ loading: true });
        try {
            await axios.delete(`${Constants.BASE_URL}files?path=${url}`, {
                headers: {
                    'Authorization': this.props.sessionProps.account.token
                }
            });
            await this.fetchFiles();
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            console.log(error)

            toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Archivos" breadcrumbItems={[]} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label htmlFor="example-datetime-local-input" className="col-md-2 col-form-label">Archivo</Label>
                                            <Col md={10}>
                                                <div className="custom-file">
                                                    <CustomInput type="file" name="file" className="custom-file-input" onChange={this.handleFileChange} />
                                                    <Label className="custom-file-label">Seleccionar archivo</Label>
                                                </div>
                                            </Col>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Fecha</th>
                                                        <th>URL</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.files.map((file, key) =>
                                                            <tr key={file.id}>
                                                                <td>{file.id}</td>
                                                                <td>{file.date}hs</td>
                                                                <td>{Constants.BASE_URL}{file.url}</td>
                                                                <td>
                                                                    <Link onClick={() => this.deleteFile(file.url)} className="text-danger">
                                                                        <i className="mdi mdi-trash-can font-size-18" />
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container >
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Files);
