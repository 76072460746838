import React, { useState, useRef, useEffect } from 'react';
import {
    Stage,
    SubscribeType,
    StageEvents,
    ConnectionState,
    StreamType,
    LocalStageStream,
} from 'amazon-ivs-web-broadcast';
import { IVSRealTimeClient, CreateParticipantTokenCommand } from "@aws-sdk/client-ivs-realtime";
import { connect } from 'react-redux';

const BroadcastComponent = ({ configAccount }) => {
    const [connected, setConnected] = useState(false);
    const [joining, setJoining] = useState(false);
    const [streamStarted, setStreamStarted] = useState(false);

    const [devices, setDevices] = useState({ video: [], audio: [] });
    const [selectedVideoDevice, setSelectedVideoDevice] = useState(null);
    const [selectedAudioDevice, setSelectedAudioDevice] = useState(null);
    const [visibility, setVisibility] = useState("public");

    const videoRef = useRef(null);
    const localVideoRef = useRef(null);

    const [stage, setStage] = useState(null);

    useEffect(() => {
        async function fetchDevices() {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = mediaDevices.filter((device) => device.kind === "videoinput");
            const audioDevices = mediaDevices.filter((device) => device.kind === "audioinput");

            setDevices({ video: videoDevices, audio: audioDevices });

            if (videoDevices.length > 0) setSelectedVideoDevice(videoDevices[0].deviceId);
            if (audioDevices.length > 0) setSelectedAudioDevice(audioDevices[0].deviceId);
        }

        fetchDevices();
    }, []);

    const generateToken = async () => {
        if (!configAccount?.config?.arn || !configAccount?.config?.ak || !configAccount?.config?.sk) {
            alert('Atención, no se configuró correctamente la cuenta de transmisión');
            return;
        }

        const ivsRealtimeClient = new IVSRealTimeClient({
            region: 'us-east-1',
            credentials: {
                accessKeyId: configAccount?.config?.ak,
                secretAccessKey: configAccount?.config?.sk,
            },
        });
        const createStageTokenRequest = new CreateParticipantTokenCommand({
            stageArn: configAccount?.config?.arn,
            capabilities: ["PUBLISH", "SUBSCRIBE"],
        });
        const response = await ivsRealtimeClient.send(createStageTokenRequest);

        return response.participantToken?.token;
    };

    const joinStage = async () => {
        if (connected || joining || streamStarted) return;

        setJoining(true);

        const token = await generateToken();
        if (!token) {
            alert('No se pudo generar el token de conexión');
            setJoining(false);
            return;
        }

        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({
                video: selectedVideoDevice ? { deviceId: { exact: selectedVideoDevice } } : true,
                audio: selectedAudioDevice ? { deviceId: { exact: selectedAudioDevice } } : true,
            });

            if (!mediaStream) {
                alert('No se pudo obtener el stream de medios');
                setJoining(false);
                return;
            }

            if (localVideoRef.current) {
                localVideoRef.current.srcObject = mediaStream;
            }

            const audioTrack = new LocalStageStream(mediaStream.getAudioTracks()[0]);
            const videoTrack = new LocalStageStream(mediaStream.getVideoTracks()[0]);

            const strategy = {
                audioTrack: audioTrack,
                videoTrack: videoTrack,
                updateTracks(newAudioTrack, newVideoTrack) {
                    this.audioTrack = newAudioTrack;
                    this.videoTrack = newVideoTrack;
                },
                stageStreamsToPublish() {
                    return [this.audioTrack, this.videoTrack];
                },
                shouldPublishParticipant() {
                    return true;
                },
                shouldSubscribeToParticipant() {
                    return SubscribeType.AUDIO_VIDEO;
                }
            };

            const stageTmp = new Stage(token, strategy);

            stageTmp.on(StageEvents.STAGE_CONNECTION_STATE_CHANGED, (state) => {
                const isConnected = state === ConnectionState.CONNECTED;
                setConnected(isConnected);
                setJoining(false);
                setStreamStarted(isConnected);
            });

            stageTmp.on(StageEvents.STAGE_STREAM_PUBLISH_STARTED, () => {
                console.log('Stream iniciado correctamente.');
            });

            stageTmp.on(StageEvents.STAGE_STREAM_PUBLISH_ERROR, (error) => {
                console.log('Error al publicar el stream:', error);
            });

            stageTmp.on(StageEvents.STAGE_PARTICIPANT_STREAMS_ADDED, (participant, streams) => {
                const videoStreams = streams.filter((stream) => stream.streamType === StreamType.VIDEO);
                const audioStreams = streams.filter((stream) => stream.streamType === StreamType.AUDIO);

                const mediaStreamTracks = [
                    ...videoStreams.map((stream) => stream.mediaStreamTrack),
                    ...audioStreams.map((stream) => stream.mediaStreamTrack),
                ];

                if (videoRef.current) {
                    videoRef.current.srcObject = new MediaStream(mediaStreamTracks);
                }
            });

            await stageTmp.join();
            setStage(stageTmp);
            console.log('Unido al Stage correctamente.');
        } catch (error) {
            console.error('Error al unirse al Stage:', error.message || error);
            setJoining(false);
            setConnected(false);
        }
    };

    const leaveStage = () => {
        if (stage) {
            stage.leave();
        }

        setJoining(false);
        setConnected(false);
        setStreamStarted(false);

        // reset video elements
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
    };

    return (
        <div>
            <div>
                <label>
                    Cámara:
                    <select
                        value={selectedVideoDevice || ""}
                        onChange={(e) => setSelectedVideoDevice(e.target.value)}
                    >
                        {devices.video.map((device) => (
                            <option key={device.deviceId} value={device.deviceId}>
                                {device.label || `Cámara ${device.deviceId}`}
                            </option>
                        ))}
                    </select>
                </label>
                <label style={{ marginLeft: '1rem' }}>
                    Micrófono:
                    <select
                        value={selectedAudioDevice || ""}
                        onChange={(e) => setSelectedAudioDevice(e.target.value)}
                    >
                        {devices.audio.map((device) => (
                            <option key={device.deviceId} value={device.deviceId}>
                                {device.label || `Micrófono ${device.deviceId}`}
                            </option>
                        ))}
                    </select>
                </label>
            </div>
            <div style={{ margin: '1rem 0', display: 'flex', gap: '1rem' }}>
                <button onClick={joinStage} disabled={joining || connected || streamStarted}>
                    {joining ? 'Conectando...' : 'Iniciar Stream'}
                </button>
                <button onClick={leaveStage} disabled={!connected}>
                    Detener Stream
                </button>
                {/* SELECT PARA PREGUNTAR SI MOSTRARLO PULICO O NO */}
                <select
                    value={visibility}
                    onChange={(e) => setVisibility(e.target.value)}
                >
                    <option value="public">Público</option>
                    <option value="private">Privado</option>
                </select>
            </div>
            <div style={{ display: 'flex', gap: '1rem' }}>
                <video
                    ref={localVideoRef}
                    autoPlay
                    muted
                    playsInline
                    style={{ width: '50%', border: '1px solid #ccc' }}
                />
                <video
                    ref={videoRef}
                    autoPlay
                    playsInline
                    controls
                    style={{ width: '50%' }}
                />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    configAccount: state.accountReducer
});

export default connect(mapStateToProps, null)(BroadcastComponent);
