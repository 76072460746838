import React, { Component } from 'react';
import './ModalShowWarningInvoice.scss';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';

class ModalShowWarningInvoice extends Component {

    close = () => {
        this.props.close();
    }

    render() {
        return (
            <div className="container-modal" id="modal-show-warning-invoice">
                <div className="content-modal">
                    <Container>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div onClick={() => this.close()} style={{ textAlign: 'right', cursor: 'pointer' }}>
                                            X
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <h3>¡Atención!</h3>
                                            <h6 style={{ marginTop: '2rem' }}>
                                                Tienes mantenimiento pendiente de pago.<br /> Comunícate con nosotros
                                                para poder seguir utilizando la plataforma con normalidad.
                                            </h6>
                                            <button onClick={() => this.close()}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        );
    }
}

export default ModalShowWarningInvoice;