import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from "react-router-dom";
// Routes
import { privateRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
// Layouts
import VerticalLayout from "./components/VerticalLayout/";
import PublicLayout from "./components/PublicLayout";
// Import scss
import "./Styles.scss";
import { connect } from 'react-redux';
import { userIsLoggedAction } from './redux/actions/UserActions';
import { configAccountAction } from './redux/actions/AccountActions';
import { Constants } from './Constants';

class App extends Component {
  constructor(props) {
    super(props);
    this.props.userIsLogged();
    this.props.getAccountConfig();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.configAccount.config !== this.props.configAccount.config) {
      if (this.props.configAccount.config?.title_tabs !== null) {
        document.title = this.props.configAccount.config.title_tabs + ' - Panel Administración';
      }
      if (this.props.configAccount.config?.favicon_admin !== null) {
        let link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
        link.href = Constants.BASE_URL + this.props.configAccount.config.favicon_admin;
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            {
              this.props.sessionProps.account !== null ?
                privateRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={VerticalLayout}
                    component={route.component}
                    key={idx}
                  />
                )) :
                publicRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={PublicLayout}
                    component={route.component}
                    key={idx}
                  />
                ))
            }
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sessionProps: state.userReducer,
  configAccount: state.accountReducer
})

const mapDispatchToProps = (dispatch) => ({
  userIsLogged: () => {
    dispatch(userIsLoggedAction())
  },
  getAccountConfig: () => {
    dispatch(configAccountAction())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
