import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Select from 'react-select';
import { customersListAutocomplete } from '../../services/UserServices';
import { connect } from 'react-redux';
import { getLotsForLiquidate, calculateTotalsLiquid, printRemitterLiquid, invoiceLiquidation } from '../../services/LotServices';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import './RemitterLiquid.scss';
import { confirmAlert } from 'react-confirm-alert';
import { auctionListAutocomplete } from '../../services/AuctionServices';

class RemitterLiquid extends Component {
    state = {
        breadcrumbItems: [
            { title: "Remisiones", link: "#" },
            { title: 'Liquidar', link: "#" }
        ],
        customers: [],
        lots: [],
        customerSelect: null,
        loading: false,
        checkAll: false,
        totals: {
            total: 0,
            comision: 0,
            iva: 0,
            totalToPay: 0,
        },
        moneySymbol: null,
        auctions: [],
        auctionSelect: null,
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchCustomers();
        this.fetchAuctions();
    }

    fetchAuctions = async () => {
        try {
            let data = await auctionListAutocomplete({
                token: this.props.sessionProps.account.token,
                all: true
            });
            await this.setState({
                auctions: [...data.data.data.auctions]
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token,
                onlyRemitter: true,
                auctionSelect: this.state.auctionSelect?.value ? this.state.auctionSelect.value.id : null
            });
            await this.setState({
                customers: response.data.data.customers,
                customerSelect: null
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    loadLots = async (e = null) => {
        this.initTotals();
        await this.setState({ loading: true, customerSelect: e !== null ? e : this.state.customerSelect });
        try {
            let response = await getLotsForLiquidate({
                token: this.props.sessionProps.account.token,
                customerId: this.state.customerSelect.value
            });
            let lots = response.data.data.lots;
            lots.map(item => item.checked = false);
            await this.setState({
                lots: response.data.data.lots,
                loading: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    setChecked = async (index) => {
        let lots = this.state.lots;
        lots[index].checked = !this.state.lots[index].checked;
        await this.setState({
            lots: lots
        });
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            this.initTotals();
        }
        else {
            this.calculateTotals();
        }
    }

    setCheckedAll = async () => {
        let lots = this.state.lots;
        lots.map(item => item.checked = !this.state.checkAll);
        await this.setState({
            checkAll: !this.state.checkAll,
            lots: lots
        });
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            this.initTotals();
        }
        else {
            this.calculateTotals();
        }
    }

    initTotals = async () => {
        await this.setState({
            totals: {
                total: 0,
                comision: 0,
                iva: 0,
                totalToPay: 0,
            }
        });
    }

    calculateTotals = async () => {
        try {
            let response = await calculateTotalsLiquid({
                user: this.state.customerSelect.value,
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true)
            });
            await this.setState({
                ...this.state,
                totals: {
                    total: response.data.data.subtotal,
                    comision: response.data.data.comision,
                    iva: response.data.data.iva,
                    totalToPay: response.data.data.totalToPay,
                },
                moneySymbol: this.state.lots.length > 0 ? this.state.lots[0].auction.moneySymbol : 'UYU'
            });
        } catch (error) {
            console.log(error)
        }
    }

    handleSelectChange = async (selectedOption) => {
        this.initTotals();
        let signMoney = this.state.signMoney;
        this.state.moneys.map(item => { if (item.value === selectedOption.value.money) { signMoney = item } });
        await this.setState({
            auctionSelect: selectedOption,
            signMoney: signMoney,
            userSelect: null,
            lots: [],
            checkAll: false
        });
    };

    handleSelectChangeAuction = async (selectedOption) => {
        this.initTotals();
        await this.setState({
            auctionSelect: selectedOption,
            userSelect: null,
            lots: [],
            checkAll: false
        });
        this.fetchCustomers();
    };

    invoiceLots = () => {
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            toast.error('Debe seleccionar lotes a liquidar', {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        confirmAlert({
            title: 'Liquidar lotes',
            message: `¿Esta seguro que desea liquidar los lotes seleccionados?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._invoiceLots()
                    }
                }
            ]
        });
    }

    _invoiceLots = async () => {
        await this.setState({ loading: true });
        try {
            let data = {
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true),
            }
            await invoiceLiquidation(data);
            await this.downloadPdfs();
            await this.setState({ loading: false });
            this.loadLots();
            toast(`Lotes liquidados con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    _downloadPdfs = () => {
        confirmAlert({
            title: 'Descargar reporte de ventas',
            message: `¿Esta seguro que desea descargar el reporte de ventas?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.downloadPdfs()
                    }
                }
            ]
        });
    }

    downloadPdfs = async () => {
        await this.setState({ loading: true });
        try {
            let response;
            let link = document.createElement('a');
            response = await printRemitterLiquid({
                user: this.state.customerSelect.value,
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true),
                invoiceId: false,
            });
            link.href = response.data.data.url;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
            let msg = 'Ocurrió un error, reintentar.';
            if (error !== undefined && error.response !== undefined && error.response.data !== undefined) {
                msg = error.response.data.message;
            }
            toast.error(msg, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
        }
    }

    render() {
        return (
            <div>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Liquidar remisiones" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Remate</Label>
                                            <Col md={10} style={{ zIndex: 9 }}>
                                                <Select
                                                    options={[
                                                        { value: null, label: 'Todos los remates' },
                                                        ...this.state.auctions.map(item => ({ value: item, label: item.title }))
                                                    ]}
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar filtro de remate"
                                                    value={this.state.auctionSelect}
                                                    onChange={this.handleSelectChangeAuction}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Remitente</Label>
                                            <Col md={10}>
                                                <Select
                                                    options={this.state.customers.map(item => ({ value: item.id, label: `${item.id_user} - ${item.name}` }))}
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar remitente a buscar"
                                                    onChange={this.loadLots}
                                                    value={this.state.customerSelect}
                                                />
                                            </Col>
                                        </FormGroup>
                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {
                                                                this.state.lots.length > 0 &&
                                                                <input type="checkbox" checked={this.state.checkAll} onChange={() => this.setCheckedAll()} />
                                                            }
                                                        </th>
                                                        <th>Nombre</th>
                                                        <th>Remate</th>
                                                        <th>Lote</th>
                                                        <th>Cantidad</th>
                                                        <th>Precio subastado</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.lots.map((item, index) =>
                                                            <tr>
                                                                <td><input type="checkbox" checked={item.checked} onChange={() => this.setChecked(index)} /></td>
                                                                <td>{item.title}</td>
                                                                <td>{item.auction.title}</td>
                                                                <td>{item.id_lot}</td>
                                                                <td>{item.quantity}</td>
                                                                <td>{item.auction.moneySymbol} {item.offer}</td>
                                                                <td>
                                                                    <a href={`/remisiones/${item.id}`} target="_blank" className="mr-3 text-primary"><i className="mdi mdi-layers-search font-size-22"></i></a>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        {
                                            this.state.moneySymbol !== null &&
                                            <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                                                <div className="col-12 text-right" style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                                                    <div>
                                                        <h6>Sub total</h6>
                                                        <h6>Comisión</h6>
                                                        <h6>IVA</h6>
                                                        <h5>Total a cobrar</h5>
                                                    </div>
                                                    <div style={{ marginLeft: '0.5rem' }}>
                                                        <h6>{this.state.moneySymbol}</h6>
                                                        <h6>{this.state.moneySymbol}</h6>
                                                        <h6>{this.state.moneySymbol}</h6>
                                                        <h5>{this.state.moneySymbol}</h5>
                                                    </div>
                                                    <div className="text-right" style={{ marginLeft: '0.2rem' }}>
                                                        <h6>{this.state.totals.total}</h6>
                                                        <h6>{this.state.totals.comision}</h6>
                                                        <h6>{this.state.totals.iva}</h6>
                                                        <h5>{this.state.totals.totalToPay}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '2rem' }}>
                                            <div>
                                                <Button type="button" color="info" className="ml-2" onClick={this.invoiceLots}
                                                    disabled={parseInt(this.state.totals.totalToPay) <= 0}>
                                                    <i className="mdi mdi-receipt"></i> Liquidar
                                                </Button>
                                                <Button type="button" color="success" className="ml-2" onClick={this._downloadPdfs}>
                                                    <i className="mdi mdi-pdf-box"></i> Reporte
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemitterLiquid);