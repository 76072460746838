import React, { useEffect, useState } from "react";
// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { getAlertsByClient, setAlertSeen } from "../../services/Alerts";
import { Constants } from "../../Constants";
import ModalAlert from "../Shared/modalAlerts";

const Layout = (props) => {

  const [isMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const [alerts, setAlerts] = useState([]);

  const updateAlert = async (id) => {
    await setAlertSeen(id);
  }

  useEffect(() => {
    /* const fetchAlerts = async () => {
      const alerts = await getAlertsByClient(Constants.CLIENT_ID_API_MASTER);
      setAlerts(alerts.data.alerts);
    }
    window.scrollTo(0, 0);
    fetchAlerts(); */
  }, []);

  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header />
        {
          alerts && alerts.length ? alerts.map(alert => (
            <ModalAlert message={alert.message} title={alert.title} id={alert.id} updateAlert={updateAlert} />
          )) : null
        }
        <Sidebar
          isMobile={isMobile}
        />
        <div className="main-content">
          {props.children}
          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Layout;