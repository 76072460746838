import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logoutAction } from '../../redux/actions/UserActions';
import { connect } from 'react-redux';

// users
import avatar from '../../assets/images/avatar.png';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
        };
        this.toggle = this.toggle.bind(this);
    }


    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    logout = () => {
        this.props.logout();
    }

    getUsername = () => {
        let username = this.props.sessionProps.account.email.split("@")[0];
        return username;
    }

    render() {
        let username = this.getUsername();
        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        <img className="rounded-circle header-profile-user mr-1" src={avatar} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ml-1 text-transform">{username}</span>
                        <i className="mdi mdi-chevron-down d-none ml-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem className="text-danger" onClick={this.logout}>
                            <i className="ri-shut-down-line align-middle mr-1 text-danger"></i>Logout
                        </DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer
})

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMenu);