import { Actions } from "../actions/Actions";

const initialState = {
    loading: false,
    error: null,
    account: null
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.USER_LOGIN_LOADING:
            return { ...state, loading: true };
        case Actions.USER_LOGIN_SUCCESS:
            return { ...state, loading: false, error: null, account: action.data.account };
        case Actions.USER_LOGIN_ERROR:
            return { ...state, loading: false, error: action.data.message };
        default:
            return state;
    }
}