import React, { useEffect, useState } from 'react';
import { Container, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { getAllPayments } from '../../services/Payments';
import { Constants } from '../../Constants';
import PaginationCustom from '../../components/pagination/Pagination';

const client_id = Constants.CLIENT_ID_API_MASTER

const StarterPage = () => {

    const breadcrumbItems = [
        { title: "Dashboard", link: "#" },
    ]
    const [payments, setPayments] = useState([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)

    useEffect(() => {
        // fetchAllPayments()
    }, [])

    const fetchAllPayments = async () => {
        try {
            const response = await getAllPayments(client_id, page)
            setPayments(response.data.payments)
            setTotal(response.data.total)
        } catch (error) {
            console.log(error)
        }
    }

    const onPrev = async () => {
        await setPage(page - 1)
        fetchAllPayments()
    }

    const onNext = async () => {
        await setPage(page + 1)
        fetchAllPayments()
    }

    const onPage = async (page) => {
        await setPage(page)
        fetchAllPayments()
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Dashboard" breadcrumbItems={breadcrumbItems} />
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '4rem',
                        background: 'white',
                        marginBottom: '2rem',
                        textAlign: 'center',
                        fontSize: '19px',
                        fontWeight: 'bold',
                    }}>
                        <div>
                            <p>PRÓXIMAMENTE AQUÍ MAS INFORMACIÓN</p>
                            <p>¿Que datos te gustaría ver? Contame por Whatsapp</p>
                        </div>
                    </div>
                    { /*
                    <h4 className="card-title">Pagos</h4>
                    <div className="table-responsive" style={{ marginTop: '1rem' }}>
                        <Table>
                            <thead>
                                <tr style={{ background: 'white' }}>
                                    <th>Mes</th>
                                    <th>Subastas</th>
                                    <th>Monto</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody style={{ background: ' white' }}>
                                {
                                    payments?.map((payment, index) => (
                                        <tr key={index}>
                                            <td>{payment.month}/{payment.year}</td>
                                            <td>{payment.qty_auctions}</td>
                                            <td>USD {payment.amount}</td>
                                            <td>
                                                {
                                                    payment.status ?
                                                        <div className="badge badge-soft-success font-size-12">PAGO</div> :
                                                        <div className="badge badge-soft-warning font-size-12">PENDIENTE</div>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        <PaginationCustom page={page} setPage={setPage} total={total}
                            onPrev={onPrev} onNext={onNext} onPage={onPage}
                        />
                    </div>*/ }
                </Container>
            </div >
        </React.Fragment >
    );
}

export default StarterPage;