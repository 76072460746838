import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Button, Table } from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Select from 'react-select';
import { customersListAutocomplete } from '../../services/UserServices';
import { connect } from 'react-redux';
import { getLotsForDelivery, deliveryLots, calculateTotals } from '../../services/LotServices';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import { logoutAction } from '../../redux/actions/UserActions';
import { auctionListAutocomplete, downloadPdfs } from '../../services/AuctionServices';
import './LotsDeliver.scss';
import { confirmAlert } from 'react-confirm-alert';
import { Constants } from '../../Constants';

class LotsDeliver extends Component {
    state = {
        breadcrumbItems: [
            { title: "Lotes", link: "#" },
            { title: 'Entregar', link: "#" }
        ],
        customers: [],
        lots: [],
        userId: null,
        userSelect: null,
        loading: false,
        checkAll: false,
        auctions: [],
        auctionSelect: null,
        totals: {
            total: 0,
            comision: 0,
            iva: 0,
            packing_cost: 0,
            totalToPay: 0,
        },
        excludeIva: false,
    }

    componentDidMount = () => {
        toast.configure();
        this.fetchCustomers();
        this.fetchAuctions();
    }

    fetchCustomers = async () => {
        try {
            let response = await customersListAutocomplete({
                token: this.props.sessionProps.account.token
            });
            await this.setState({
                customers: response.data.data.customers
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    fetchAuctions = async () => {
        try {
            let data = await auctionListAutocomplete({
                token: this.props.sessionProps.account.token,
                all: true
            });
            await this.setState({
                auctions: [...data.data.data.auctions]
            });
        } catch (error) {
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    loadLots = async (userId, e = null) => {
        this.initTotals();
        await this.setState({ loading: true, userId: userId });
        try {
            let response = await getLotsForDelivery({
                token: this.props.sessionProps.account.token,
                userId: userId,
                auction: this.state.auctionSelect !== null ? this.state.auctionSelect.value.id : null
            });
            let lots = response.data.data.lots;
            lots.map(item => item.checked = false);
            await this.setState({
                lots: response.data.data.lots,
                userId: userId,
                loading: false,
                userSelect: e !== null ? e : this.state.userSelect,
                checkAll: false
            });
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    deliveryLots = () => {
        confirmAlert({
            title: 'Entregar lotes',
            message: `¿Esta seguro que desea entregar los lotes seleccionados?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this._deliveryLots()
                    }
                }
            ]
        });
    }

    _deliveryLots = async () => {
        await this.setState({ loading: true });
        try {
            let response = await deliveryLots({
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true)
            });
            toast(`Lotes entregados con éxito`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            await this.setState({ loading: false });
            this.loadLots(this.state.userId);
        } catch (error) {
            await this.setState({ loading: false });
            if (error !== undefined && error.response !== undefined && error.response.status === 401) {
                this.props.logout();
                this.props.history.push('/');
            }
            else {
                toast.error(`Ocurrió un error al procesar la solicitud, reintentar`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    pauseOnHover: false,
                    duration: 10000
                });
            }
        }
    }

    setChecked = async (index) => {
        let lots = this.state.lots;
        lots[index].checked = !this.state.lots[index].checked;
        await this.setState({
            lots: lots
        });
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            this.initTotals();
        }
        else {
            this.calculateTotals();
        }
    }

    setCheckedAll = async () => {
        let lots = this.state.lots;
        lots.map(item => item.checked = !this.state.checkAll);
        await this.setState({
            checkAll: !this.state.checkAll,
            lots: lots
        });
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            this.initTotals();
        }
        else {
            this.calculateTotals();
        }
    }

    initTotals = async () => {
        await this.setState({
            totals: {
                total: 0,
                comision: 0,
                iva: 0,
                packing_cost: 0,
                totalToPay: 0,
            }
        });
    }

    calculateTotals = async () => {
        try {
            let response = await calculateTotals({
                user: this.state.userId,
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true),
                excludeIva: this.state.excludeIva
            });
            await this.setState({
                ...this.state,
                totals: {
                    total: response.data.data.total,
                    comision: response.data.data.comision,
                    iva: response.data.data.iva,
                    packing_cost: response.data.data.packing_cost,
                    totalToPay: response.data.data.totalToPay,
                }
            });
        } catch (error) {
        }
    }

    handleSelectChange = async (selectedOption) => {
        this.initTotals();
        await this.setState({
            auctionSelect: selectedOption,
        });
        if (this.state.userId !== null) {
            this.loadLots(this.state.userId);
        }
    };

    _downloadPdfs = () => {
        confirmAlert({
            title: 'Descargar PDFs de compradores',
            message: `¿Esta seguro que desea descargar los PDFs de los compradores?`,
            buttons: [
                {
                    label: 'Cancelar'
                },
                {
                    label: 'Confirmar',
                    onClick: () => {
                        this.downloadPdfs()
                    }
                }
            ]
        });
    }

    downloadPdfs = async () => {
        if (this.state.lots.filter(item => item.checked === true).length === 0) {
            toast.error(`Debe seleccionar los lotes a descargar.`, {
                position: toast.POSITION.BOTTOM_CENTER,
                pauseOnHover: false,
                duration: 10000
            });
            return;
        }
        await this.setState({ loading: true });
        try {
            let response = await downloadPdfs({
                usersId: [this.state.userId],
                auctionId: this.state.auctionSelect !== null ? this.state.auctionSelect.value.id : null,
                token: this.props.sessionProps.account.token,
                lots: this.state.lots.filter(item => item.checked === true),
                excludeIva: this.state.excludeIva
            });
            let link = document.createElement('a');
            link.href = response.data.data.url;
            link.download = `entrega_lotes_${this.state.userId}.pdf`;
            link.target = "_blank";
            document.body.appendChild(link);
            link.click();
            await this.setState({ loading: false });
        } catch (error) {
            await this.setState({ loading: false });
        }
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    render() {
        return (
            <div>
                <div className="page-content">
                    {this.state.loading && <Loading />}
                    <Container fluid>
                        <Breadcrumbs title="Entregar lotes" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Cliente</Label>
                                            <Col md={10}>
                                                <Select
                                                    options={this.state.customers.map(item => ({ value: item.id_user, label: item.name }))}
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar cliente a buscar"
                                                    onChange={(e) => this.loadLots(e.value, e)}
                                                    value={this.state.userSelect}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label className="col-md-2 col-form-label">Remate</Label>
                                            <Col md={10}>
                                                <Select
                                                    options={this.state.auctions.map(item => ({ value: item, label: item.title }))}
                                                    className="select2 select2-multiple"
                                                    placeholder="Seleccionar filtro de remate"
                                                    value={this.state.auctionSelect}
                                                    onChange={this.handleSelectChange}
                                                    isDisabled={this.state.userId === null}
                                                />
                                            </Col>
                                        </FormGroup>

                                        <div className="table-responsive">
                                            <Table className="mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {
                                                                this.state.lots.length > 0 &&
                                                                <input type="checkbox" checked={this.state.checkAll} onChange={() => this.setCheckedAll()} />
                                                            }
                                                        </th>
                                                        <th>Lote</th>
                                                        <th>Nombre</th>
                                                        <th>Remate</th>
                                                        <th>Precio subastado</th>
                                                        {
                                                            this.props.configAccount?.config?.use_cost_packing ?
                                                                <th>Costo embalaje</th> : null
                                                        }
                                                        <th>Estado</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.lots.map((item, index) =>
                                                            <tr>
                                                                <td><input type="checkbox" checked={item.checked} onChange={() => this.setChecked(index)} /></td>
                                                                <th scope="row">{item.id_lot}</th>
                                                                <td>{item.title}</td>
                                                                <td>{item.auction.title}</td>
                                                                <td>{item.auction.moneySymbol} {item.offer}</td>
                                                                {
                                                                    this.props.configAccount?.config?.use_cost_packing ?
                                                                        <td>{item.auction.moneySymbol} {item.packing_cost}</td> : null
                                                                }
                                                                <td>
                                                                    {item.status == Constants.STATUS_PENDING && <div className="badge badge-soft-info font-size-12">Subastado</div>}
                                                                    {item.status == Constants.STATUS_DELIVERED && <div className="badge badge-soft-success font-size-12">Entregado</div>}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                        {
                                            this.state.lots.filter(lot => lot.checked).length ?
                                                <div className="row" style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                                                    <div className="col-12 text-right" style={{ display: 'inline-flex', justifyContent: 'flex-end' }}>
                                                        <div>
                                                            <h6>Sub total</h6>
                                                            <h6>Comisión</h6>
                                                            <div style={{ display: 'flex', gap: '0.5rem', marginBottom: '0.5rem', alignItems: 'center', justifyContent: 'end' }}>
                                                                <input type="checkbox" checked={!this.state.excludeIva} onChange={
                                                                    async () => {
                                                                        await this.setState({ excludeIva: !this.state.excludeIva });
                                                                        this.calculateTotals();
                                                                    }
                                                                } />
                                                                <h6 style={{ marginBottom: '0rem' }}>IVA</h6>
                                                            </div>
                                                            {
                                                                this.props.configAccount?.config?.use_cost_packing ?
                                                                    <h6>Costo embalaje</h6> : null
                                                            }
                                                            <h5>Total a pagar</h5>
                                                        </div>
                                                        <div style={{ marginLeft: '0.5rem' }}>
                                                            <h6>{this.state.lots[0].moneySymbol}</h6>
                                                            <h6>{this.state.lots[0].moneySymbol}</h6>
                                                            <h6>{this.state.lots[0].moneySymbol}</h6>
                                                            {
                                                                this.props.configAccount?.config?.use_cost_packing ?
                                                                    <h6>{this.state.lots[0].moneySymbol}</h6> : null
                                                            }
                                                            <h5>{this.state.lots[0].moneySymbol}</h5>
                                                        </div>
                                                        <div className="text-right" style={{ marginLeft: '0.2rem' }}>
                                                            <h6>{this.state.totals.total}</h6>
                                                            <h6>{this.state.totals.comision}</h6>
                                                            <h6>{this.state.totals.iva}</h6>
                                                            {
                                                                this.props.configAccount?.config?.use_cost_packing ?
                                                                    <h6>{this.state.totals.packing_cost}</h6> : null
                                                            }
                                                            <h5>{this.state.totals.totalToPay}</h5>
                                                        </div>
                                                    </div>
                                                </div> : null
                                        }
                                        <FormGroup className="mb-0 text-right" style={{ marginTop: '2rem' }}>
                                            <div>
                                                {
                                                    this.state.lots.filter(item => item.checked).filter(item => item.status === Constants.STATUS_DELIVERED).length === 0 &&
                                                    <Button type="button" color="danger" className="ml-2" onClick={this.deliveryLots}>
                                                        <i className="mdi mdi-check-box-outline"></i> Entregar
                                                    </Button>
                                                }
                                                <Button type="button" color="success" className="ml-2" onClick={this._downloadPdfs}>
                                                    <i className="mdi mdi-pdf-box"></i> Reporte
                                                </Button>
                                            </div>
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    sessionProps: state.userReducer,
    configAccount: state.accountReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => {
            dispatch(logoutAction());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LotsDeliver);